<template>
  <div>

    <general-table
      ref="userTable"
      :api-url="apiUrl"
      :type="type"
      :columns="columns"
      :export-data="true"
      :delete-content="false"
      :viw-component="viwComponent"
      :block-content="true"
      :edit-content="false"
      :search-type="true"
    >

      <template #filter>

        <b-col
          cols="12"
          md="2"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.blocked"
              value-field="value"
              text-field="text"
              :options="status"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Status
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.blocked">
              <b-button
                size="sm"
                @click="filter.blocked = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.mobile_verified"
              value-field="value"
              text-field="text"
              :options="verified"
              placeholder="verified"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Verified
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.mobile_verified">
              <b-button
                size="sm"
                @click="filter.mobile_verified = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>

        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-input-group>
            <b-form-datepicker
              v-model="filter.from"
              placeholder="Start Date"
              reset-button
              close-button
              locale="en-GB"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-input-group></b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-input-group>
            <b-form-datepicker
              v-model="filter.to"
              placeholder="End Date"
              reset-button
              close-button
              locale="en-GB"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-input-group></b-col>

        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.userTable.getAllData()"
        >
          Filter
        </b-button>

      </template>
      <template #cell(status)="data">
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.status)"
        >
          {{ data.item.status }}
        </b-badge>
      </template>
      <template #cell(gender)="data">
        <span v-if="data.item.gender == 'null'">
            &nbsp;
        </span>
        <span v-else>
          {{ data.item.gender }}
        </span>
      </template>
    </general-table>
    <div>
      <b-modal
        id="status"
        ref="active-type-modal"
        hide-footer
        centered
        title="Change Status Status"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label="Activation Types">
              <b-form-select
                v-model="userForm.status"
                value-field="value"
                text-field="text"
                :options="types"
                right
              >
                <template #first>
                  <b-form-select-option
                    disabled
                    :value="userForm.status"
                  >
                    {{ userForm.status }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="userForm.status === 'Rejected'"
            cols="12"
            md="6"
          >
            <b-form-group label="Rejection Reason">
              <b-form-input
                v-model="userForm.rejection_reason"
                placeholder="Type reason here"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              :disabled="disableUpdateStatusBtn"
              @click.prevent="changeType()"
            >
              Save
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="userstatus"
        ref="block-modal"
        hide-footer
        centered
        title="Block User"
      >
        <b-row>
          <b-col
            cols="12"
            class="p-2"
          >
            Are you sure you want to block this user ?
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              :disabled="disableUpdateStatusBtn"
              @click.prevent="blockUser(1)"
            >
              Yes
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="userstatus"
        ref="unblock-modal"
        hide-footer
        centered
        title="Block User"
      >
        <b-row>
          <b-col
            cols="12"
            class="p-2"
          >
            Are you sure you want to unblock this user ?
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              :disabled="disableUpdateStatusBtn"
              @click.prevent="blockUser(0)"
            >
              Save
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div>
      <b-modal
        ref="view-modal"
        hide-footer
        centered
      >
        <view-user />
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import handleFormData from '@/global-compositions/handleFormData'
import ViewUser from './control-users/ViewUser.vue'

export default {
  components: { GeneralTable, ViewUser },
  data() {
    return {
      addType: 'Add User',
      APIURL: 'admin/users',
      countries: '',
      addComponentName: 'add-user-customer',
      editComponent: 'edit-user-customer',
      viwComponent: 'show-user-customer',
      type: 'page',
      columns: [
        { key: 'id', sortable: true },
        { key: 'avatar_url', label: 'Image', sortable: false },
        { key: 'name', sortable: true },
        { key: 'mobile', sortable: true },
        { key: 'country_name', label: 'Country' },
        { key: 'email', sortable: true },
        { key: 'is_verified', label: 'verified' },
        { key: 'status', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'actions' },
      ],
      status: [
        { text: 'Active', value: '0' },
        { text: 'Blocked', value: '1' },
      ],
      verified: [
        { text: 'Verified', value: '1' },
        { text: 'Not Verified', value: '0' },
      ],
      loader: false,
      userForm: {},
      types: {},
      currentTime: '',
      role: '',
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()
    const filter = ref({})

    const tableOptions = ref({
      filter: null,
      currentPage: 1,
      perPage: 10,
    })
    return {
      formData, setFormData, filter, tableOptions,

    }
  },

  computed: {
    ...mapGetters({
      userId: 'generalIds/getId',
    }),

    apiUrl() {
      let base = 'admin/users?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
    apiUrlExport() {
      let base = 'admin/users?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
    disableUpdateStatusBtn() {
      return this.userForm.status === 'Rejected' && !this.userForm.rejection_reason
    },
  },
  // watch: {
  //   '$route.query': {
  //     immediate: true,
  //     handler() {
  //       this.getDataToExport()
  //       // make actions with newVal.page
  //     },
  //   },
  // },
  mounted() {
    this.getCurrentDateTime()
    // this.getDataToExport()
  },
  methods: {

    async getDataToExport() {
      await axios.get(this.apiUrlExport, {
        params: {
          search: this.$route.query.search,
        },
      }).then(res => {
        if (res.status === 200) {
          this.exportedData = res.data.data.users
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          } setTimeout(() => {
            this.errorMsg = ''
          }, 2500)
        }
      }).finally(() => {
      })
    },
    changestatus(data) {
      this.userForm = data
      this.filterTypes(data.status)
      this.$bvModal.show('status')
    },
    closeModal() {
      this.$bvModal.hide('status')
      this.$bvModal.hide('userstatus')
    },

    changeType() {
      if (this.userForm?.id) {
        this.loader = true
        if (this.userForm.status === 'Accepted') {
          this.userForm.activation_at = this.currentTime
        } else {
          this.userForm.activation_at = ''
        }
        this.setFormData(this.userForm)
        this.formData.append('_method', 'PUT')
        axios.post(`users/${this.userForm.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.closeModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Succesfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        }).catch(() => {
          // this.formData = new FormData()
        }).finally(() => {
          this.loader = false
        })
      }
    },
    blockUser(isBlocked) {
      this.loader = true
      const blockParams = {
        is_blocked: isBlocked,
      }
      axios.post(`admin/change_blocked_status/${this.userId}`,
        blockParams).then(res => {
        if (res.status === 200) {
          this.closeModal()
          this.$refs.userTable.getAllData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User is updated Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Active') return 'success'

      return 'danger'
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    getCurrentDateTime() {
      const today = new Date()

      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

      const dateTime = `${date} ${time}`

      this.currentTime = dateTime
    },
  },
}
</script>

  <style scoped>
  .change-status {
    cursor: pointer;
  }
  </style>
